import React, { useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../firebase';

import '../styles/style.css';
import '../styles/pictures.css';
import '../styles/contact.css';
import '../styles/button.css';
import '../styles/admin.css';

const AdminPanel = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    if (selectedFile) {
      const storageRef = ref(storage, `uploads/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          setUploadStatus('Feltöltés sikertelen.');
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUploadStatus('Feltöltés sikeres!');
          });
        }
      );
    } else {
      setUploadStatus('Nincs fájl kiválasztva.');
    }
  };

  const handleLogin = () => {
    if (username === 'admin' && password === 'admin') {
      setIsLoggedIn(true);
      setLoginError('');
    } else {
      setLoginError('Helytelen felhasználónév vagy jelszó');
    }
  };

  return (
    <div className="admin-panel">
      <div className="container">
        <div className="cucc">
          <img src="/images/csordogalo.png" alt="" className="csordogal" />
          <div className="login-bg glass">
            {!isLoggedIn ? (
              <div id="login">
                <img src="/images/matart-logo.png" alt="" className="logo" />
                <h1 style={{ fontWeight: 'bold' }}>KÉPEK FELTÖLTÉSE</h1>
                <br />
                <input
                  type="text"
                  placeholder="Felhasználónév"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="form-control"
                />
                <br />
                <input
                  type="password"
                  placeholder="Jelszó"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                />
                <br /><br />
                <button className="btn btn1" onClick={handleLogin}>
                  Bejelentkezés
                </button>
                {loginError && <p className="error-message">{loginError}</p>}
              </div>
            ) : (
              <div id="upload">
                <form onSubmit={handleUpload}>
                  <input
                    className="form-control tallozas"
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  <br />
                  <button className="btn btn1" type="submit">
                    Feltöltés
                  </button>
                  {uploadProgress > 0 && <p>Feltöltés: {uploadProgress}%</p>}
                  {uploadStatus && <p>{uploadStatus}</p>}
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
