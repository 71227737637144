// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC6fbCJTvSBcmH8xTJJu1IL_9A_hH6oDDs",
    authDomain: "matart-57f26.firebaseapp.com",
    projectId: "matart-57f26",
    storageBucket: "matart-57f26.appspot.com",
    messagingSenderId: "482701360851",
    appId: "1:482701360851:web:a3e5e7ff6e0c7f0e039e26"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
