import React from 'react';
import '../styles/contact.css';

const Contact = () => {
  return (
    <div className="contact-text">
      <div className="row" style={{ display: "flex", justifyContent: "center" }}>
        <article className="information card col-md-6 col-lg-5 m-3" style={{ maxWidth: "300px", textAlign:"center", paddingLeft:24, paddingRight:24, borderRadius:25}}>
          <h2 className="title">Telefon:</h2>
          <p className="info">
            Tetszik egy festményem? Telefonon is elérhető vagyok. Örömmel fogadok minden érdeklődőt a vonal túloldaláról!
          </p>
          <dl className="details">
            <div>
              <dt>+36 20 529 3663</dt>
            </div>
          </dl>
        </article>

        <article className="information card col-md-6 col-lg-5 m-3" style={{ maxWidth: "300px", textAlign:"center", paddingLeft:24, paddingRight:24, borderRadius:25 }}>
          <h2 className="title">E-mail:</h2>
          <p className="info">
            Örömmel várom az érdeklődésedet, megrendeléseidet vagy bármilyen kérdésedet e-mailben! 
            Ha érdekelnek a rendelkezésre álló műveim, írj a következő e-mail címre:
          </p>
          <dl className="details">
            <div>
              <dt><a href="mailto:tmathias0520@gmail.com">tmathias0520@gmail.com</a></dt>
            </div>
          </dl>
        </article>
      </div>
    </div>
  );
};

export default Contact;
